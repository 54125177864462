<template>
  <modal ref="modal">
    <template slot="content">
      <!-- Because the modal is using portal we need to start a new donation context -->
      <donation-context v-bind="contextProps">
        <div class="donation-modal overflow-auto">
          <div v-if="!isCompleted" class="md:grid min-h-full grid-cols-2">
            <PaymentDetails :donation="donation" />
            <Form
              v-if="areExternalLibsLoaded"
              :donation="donation"
              @completed="handleCompleted()"
            />
            <div v-else>
              <static-string> Loading, please wait...</static-string>
            </div>
          </div>
        </div>
      </donation-context>
    </template>
  </modal>
</template>

<script>
import Form from './Form.vue';
import HandleI18n from '../Utils/HandleI18n';
import ThankYou from './ThankYou.vue';
import { loadScript } from '../../utils/load-script';
import PaymentDetails from './PaymentDetails.vue';
import DonationContext from '../DonationContext.vue';

export default {
  mixins: [HandleI18n],

  inject: [
    'stripePublicKey',
    'reCaptchaSiteKey',
    'newsletterFormUrl',
    'paypalMerchantId',
    'paypalSuccessPageUrl',
    'paypalCancelPageUrl',
    'ipnWebhook'
  ],

  components: { Form, ThankYou, PaymentDetails, DonationContext },

  props: {
    donation: { required: true, type: Object }
  },

  data() {
    return {
      isCompleted: false,
      areExternalLibsLoaded: false,
      newsletterFormHTML: null
    };
  },

  mounted() {
    Promise.all([
      loadScript('https://js.stripe.com/v3/'),
      loadScript(
        'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
      )
    ])
      .then(() => (this.areExternalLibsLoaded = true))
      // TODO: make it pretty
      .catch(() => alert('Cannot load Stripe right now!'));

    // Pre-fetch the newsletter form HTML for when the form is completed
    fetch(this.newsletterFormUrl)
      .then((response) => response.text())
      .then((html) => (this.newsletterFormHTML = html));
  },

  computed: {
    contextProps() {
      return {
        stripePublicKey: this.stripePublicKey,
        i18n: this.i18n,
        reCaptchaSiteKey: this.reCaptchaSiteKey,
        newsletterFormUrl: this.newsletterFormUrl,
        paypalMerchantId: this.paypalMerchantId,
        paypalSuccessPageUrl: this.paypalSuccessPageUrl,
        paypalCancelPageUrl: this.paypalCancelPageUrl,
        ipnWebhook: this.ipnWebhook,
      };
    }
  },

  methods: {
    handleCompleted() {
      this.isCompleted = true;
      let donationInterval = ' ';

      if (this.donation.interval == 'once') {
        donationInterval = 'oneoff-stripe';
      }
      else {
        donationInterval = 'monthly-stripe';
      }

      _mtm.push({
        'event': 'mtm_event',
        'event_category': 'Donations',
        'event_action':donationInterval,
        'event_name': this.donation.campaign.title,
        'event_value': `${ this.donation.amount }`
      });

      const locale = window.location.pathname.split('/')[1];
      const url = `/${locale}/donate-stripe-thank-you`;

      window.location = url;
    },

    open() {
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    }
  }
};
</script>
